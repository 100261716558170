import React, { memo, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import CheckOutSidebar from "./CheckOutSidebar";
import { ReactSVG } from "react-svg";
import locationSVG from "../../../../assets/svg/location-dot.svg";
import CalenderSVG from "../../../../assets/svg/calendar.svg";
import ClockSVG from "../../../../assets/svg/clock.svg";
import Loading from "../../../Loading/Loading";
import { useNavigate, useParams } from "react-router-dom";
import Seatics from "../Seatics";
import SidebarSkeleton from "../../../Loading/SidebarSkeleton";
import moment from "moment";
import TicketBox from "./TicketBox";

const Sidebar = ({
  filterevent,
  receivedData,
  reviceImage,
  SeaticsData,
  setSeaticsData,
  qty,
  setQty,
  splitList,
  ticketData,
}) => {
  const [active, setActive] = useState(1);
  const [SingleTicket, setSingleTicket] = useState([]);
  const GroData = useSelector((state) => state.events.EventDetails);
  const AllEventLoading = useSelector((state) => state.events.AllEventLoading);
  const SibgleEventLoading = useSelector((state) => state.events.isLoading);
  const [newSeaticsData, setNewSeaticsData] = useState(SeaticsData);
  const PaymentDetails = useSelector((state) => state.events.TaxData);
  const navigate = useNavigate();
  const params = useParams();

  const clickOnButton = (id) => {
    const filterSingleEvent =
      // eslint-disable-next-line
      GroData && GroData?.filter((item) => item.id == id);
    if (qty) {
      const sendorderdata = {
        id: filterSingleEvent && filterSingleEvent[0]?.id,
        qty,
        retail_price: filterSingleEvent && filterSingleEvent[0]?.retail_price,
        format: filterSingleEvent && filterSingleEvent[0]?.format,
        service_fee: filterSingleEvent && filterSingleEvent[0]?.service_fee,
        tax: PaymentDetails?.retail?.tax,
        wholesale_price:
          filterSingleEvent && filterSingleEvent[0]?.wholesale_price,
      };
      navigate(`/checkout`, {
        state: {
          filterevent: filterevent?.length === 1 ? filterevent : receivedData,
          data: sendorderdata,
          id: params?.id,
        },
      });
    } else {
      setSingleTicket(filterSingleEvent);
    }
  };

  const chnageTickets = async (e) => {
    setQty(e.target.value);
    if (e.target.value) {
      setNewSeaticsData(
        SeaticsData.filter((item) =>
          item?.splits?.includes(parseInt(e.target.value))
        )
      );
    } else {
      setNewSeaticsData(SeaticsData);
    }
  };

  useEffect(() => {
    if (qty) {
      setNewSeaticsData(
        SeaticsData.filter((item) => item?.splits?.includes(parseInt(qty)))
      );
    } else {
      setNewSeaticsData(SeaticsData);
    }
  }, [SeaticsData]);


  return (
    <>
      <div className="!bg-transparent !bg-black  relative py-5 px-1">
        {/* --------------------  */}
        <div
          className={` ${
            SingleTicket && SingleTicket?.length > 0
              ? "left-[0%] duration-500"
              : "!left-[-150%] duration-500"
          }  `}
        >
          <CheckOutSidebar
            SingleTicket={SingleTicket || receivedData}
            SetSingleTicket={setSingleTicket}
            filterevent={filterevent?.length === 1 ? filterevent : receivedData}
          />
        </div>
        {/* --head  */}
        {SingleTicket && SingleTicket?.length === 0 && (
          <div className="deatils_sidebar">
            <div className=" px-3">
              <img
                src={reviceImage}
                alt=""
                className="my-[10px] rounded-md w-[100%] max-h-[300px]"
              />
              <h2 className="mb-1 text-[23px] text-color2 font-medium evet_namesidebar">
                {(filterevent && filterevent[0]?.name) ||
                  (receivedData && receivedData[0]?.name) ||
                  params?.name}
              </h2>
              <div className="flex justify-start place-items-center gap-[10px] my-3 flex-wrap">
                <div className="text-[13px] text-color2 my-2 font-bold location_div">
                  <ReactSVG src={locationSVG} />
                  <span className="text-color5 mx-1">
                    {(filterevent && filterevent[0]?.venue?.location) ||
                      (receivedData && receivedData[0]?.venue?.location) ||
                      params?.loc}
                  </span>
                </div>
                <div className="text-[13px] text-color2 my-2 font-bold location_div">
                  <ReactSVG src={CalenderSVG} />
                  <span className="text-color5 mx-1">
                    {(filterevent && filterevent[0]?.occurs_at?.slice(0, 10)) ||
                      (receivedData &&
                        receivedData[0]?.occurs_at?.slice(0, 10)) ||
                      params?.date?.slice(0, 10)}
                  </span>
                </div>

                <div className="text-[13px] text-color2 my-2 font-bold location_div">
                  <ReactSVG src={ClockSVG} />
                  <span className="text-color5 mx-1">
                    {(filterevent &&
                      filterevent[0]?.occurs_at &&
                      moment(filterevent[0]?.occurs_at)
                        .utcOffset("+01:00")
                        .format("h:mm A")
                        ?.split("+", 1)) ||
                      (receivedData &&
                        receivedData[0]?.occurs_at &&
                        moment(receivedData[0]?.occurs_at)
                          .utcOffset("+01:00")
                          .format("h:mm A")
                          ?.split("+", 1))}
                  </span>
                </div>
              </div>
            </div>
            {/* ----- image   */}
            <div
              className={`parent_seat_map_wraper bg-color1 siebar_image_div my-3 `}
            >
              {AllEventLoading ? (
                <div>
                  <Loading />
                </div>
              ) : (
                window?.innerWidth <= 550 && (
                  <Seatics
                    setSidebarData={setSeaticsData}
                    SeaticsData={SeaticsData}
                    GroupsEvents={ticketData}
                    event={
                      filterevent?.length === 1
                        ? filterevent && filterevent[0]
                        : receivedData && receivedData[0]
                    }
                  />
                )
              )}
            </div>

            {/* ------  */}
            {(SibgleEventLoading || SeaticsData?.length === 0) && !qty ? (
              (filterevent && filterevent[0]?.available_count === 0) || (receivedData && receivedData[0]?.available_count === 0 )? (
                <div className="my-6 h-[300px] border-[.5px] border-[#CD292E] flex justify-center place-items-center">
                  <h2 className="text-center space-x-2 text-lg text-[gray]">
                     No any ticke list found.
                  </h2>
                </div>
              ) : (
                <div className="sidebrticket_box px-2 py-2 rounded-md flex flex-col gap-3">
                  {/* <Spinner /> */}
                  {[
                    1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17,
                    18, 19, 20,
                  ].map((i) => {
                    return <SidebarSkeleton key={i} />;
                  })}
                </div>
              )
            ) : (
              <div className=" py-2 px-2 mb-[90px]">
                <div className="flex justify-between place-items-center gap-5 my-3">
                  <h2 className="text-[22px]">Select quantity</h2>
                  <select
                    className="bg-black w-[150px] my-3 rounded-3xl select border-none border-[1px]"
                    style={{
                      boxShadow: "0px 0px 5px 2px #CD292E",
                    }}
                    value={qty}
                    onChange={chnageTickets}
                  >
                    <option value="">Select</option>
                    {splitList?.map((item) => {
                      return (
                        <option key={item} value={item}>
                          {item} {item == 1 ? "Ticket" : "Tickets"}
                        </option>
                      );
                    })}
                  </select>
                </div>
                <div className="flex justify-start place-items-center gap-2 px-[10px] my-2">
                  <h2
                    className={` mb-2 font-medium allticket_sidebar_text cursor-pointer ${
                      active === 1
                        ? "!text-redColor border-b-[1px] border-b-redColor pb-1"
                        : "!text-color5"
                    }`}
                    onClick={() => setActive(1)}
                  >
                    All Tickets
                  </h2>
                </div>
                <div className=" px-2 py-2 rounded-md sidebrticket_box">
                  {qty ? (
                    newSeaticsData?.length === 0 ? (
                      <div className="my-6">
                        <h2 className="text-center space-x-2 text-[gray]">
                          The ticket quantity of{" "}
                          <span className="text-white">{qty}</span> is not
                          available in the
                          <span className="text-white">selected</span> section
                          or <span className="text-white">row</span>.{" "}
                        </h2>
                      </div>
                    ) : (
                      newSeaticsData.map((item, index) => {
                        return (
                          <TicketBox
                            clickOnButton={clickOnButton}
                            index={index}
                            item={item}
                          />
                        );
                      })
                    )
                  ) : (
                    SeaticsData?.map((item, index) => {
                      return (
                        <TicketBox
                          clickOnButton={clickOnButton}
                          index={index}
                          item={item}
                          key={index}
                        />
                      );
                    })
                  )}
                </div>
              </div>
            )}
          </div>
        )}
      </div>
    </>
  );
};

export default memo(Sidebar);
