import {createContext, useState , useContext} from "react"

const AppContext = createContext();



export const AppContextProvider = ({children})=>{

    const [page , setPage] = useState(1);

    return <AppContext.Provider value={{page,setPage}}>{children}</AppContext.Provider>
}


export const useAppContext = ()=>{
    return useContext(AppContext);
}
