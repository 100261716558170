import { ReactSVG } from "react-svg";
import "../Components/PagesCompoents/Categories/styles/Index.css";
import filterSVG from "../assets/svg/filter.svg";
import NotFoundSVG from "../assets/svg/NotFound.svg";
import { useDispatch, useSelector } from "react-redux";
import { useInView } from "react-intersection-observer";
import React, { useEffect, useMemo, useState } from "react";
import { updateFiltrationView } from "../redux/actions/State";
import "../Components/PagesCompoents/TicketsPage/style/Tickets.css";
import { FilterEvent, GetAllEvents } from "../redux/actions/Events";
import FeaturedEvent from "../Components/EventCard/LatestCard/FeaturedEvent";
import VerticalExpediaBanner from "../Components/expedia/VerticalExpediaBanner";
import TicketHeader from "../Components/PagesCompoents/TicketsPage/TicketHeader";
import HorizontalExpediaBanner from "../Components/expedia/HorizontalExpediaBanner";
import SidebarCategory from "../Components/PagesCompoents/Categories/SidebarCategory";
import LatestLoading from "../Components/Loading/LatestLoading";
import { useAppContext } from "../redux/context";

const Tickets = () => {
  const isLoading = useSelector((state) => state.events.isLoading);
  const active = useSelector((state) => state.statemanagemnt.categorySidebar);
  const total_entries = useSelector((state) => state.events.total_entries);
  const CopyofAllEvents = useSelector((state) => state.events.CopyofAllEvents);
  const UserLocationdata = useSelector((state) => state.user.userlocation);
  const AllEvents = useSelector((state) => state.events.AllEvents);
  const Filterserachevents = useSelector((state) => state.events.Filterserachevents);
  const dispatch = useDispatch();

  const [ref, inView] = useInView({
    threshold: true,
  });

  const {page, setPage} = useAppContext();
  const [alleventsState, setAllEventsState] = useState([]);
  const [value, setValue] = useState("Any Dates");
  const [isFilter, setIsFilter] = useState(false);
  const [startdate, setstartdate] = useState("");
  const [enddate, setenddate] = useState("");


  const filteredEvents = AllEvents?.filter((event) => {
    // Filter by date ranges
    // setAllEventsState(AllEvents);
    const eventDate = new Date(event.occurs_at);
    const today = new Date();

    switch (value) {
      case "This Weekend":
        const nextSaturday = new Date(today);
        nextSaturday.setDate(
          today.getDate() + ((6 - today.getDay() + 7) % 7) + 1
        ); // Find the next Saturday
        const thisWeekend = today <= eventDate && eventDate <= nextSaturday;
        return thisWeekend;
      case "This Week":
        const endOfWeek = new Date(today);
        endOfWeek.setDate(today.getDate() + 6 - today.getDay());
        const thisWeek = today <= eventDate && eventDate <= endOfWeek;
        return thisWeek;
      case "This Month":
        const endOfMonth = new Date(
          today.getFullYear(),
          today.getMonth() + 1,
          0
        );
        const thisMonth = today <= eventDate && eventDate <= endOfMonth;
        return thisMonth;
      case "Next Month":
        const startOfNextMonth = new Date(
          today.getFullYear(),
          today.getMonth() + 1,
          1
        );
        const endOfNextMonth = new Date(
          today.getFullYear(),
          today.getMonth() + 2,
          0
        );
        const nextMonth =
          startOfNextMonth <= eventDate && eventDate <= endOfNextMonth;
        return nextMonth;
      case "Next 60 Days":
        const next60Days =
          today <= eventDate &&
          eventDate <= new Date(today.getTime() + 60 * 24 * 60 * 60 * 1000);
        return next60Days;
      default:
        return true;
    }
  });


  useMemo(() => {
    setAllEventsState(AllEvents);
  }, [AllEvents]);


  useMemo(() => {
    if (inView) {
      setPage(page + 1);
    }
  }, [inView]);


  useMemo(() => {
    if (!isFilter) {
      if (page === 0) {
        const newp = 1;
        dispatch(GetAllEvents(newp, UserLocationdata));
      } else {
        dispatch(GetAllEvents(page, UserLocationdata));
      }
    } else {
      dispatch(FilterEvent(startdate, enddate, page));
    }
  }, [page]);

  useEffect(() => {
    if (isFilter) {
      setAllEventsState(Filterserachevents);
    } else {
      setAllEventsState(filteredEvents);
    }
  }, [value, Filterserachevents, isFilter]);




  return (
    <section className="!bg-color1 px-[50px]  !text-color2 body-font !z-10 ticket_page max-w-[2000px] mx-auto">
      <HorizontalExpediaBanner />
      <TicketHeader setValue={setValue} value={value} />
      <h2 className="  text-[25px] font-bold">
        Events{" "}
        <span className="text-redColor">
          ({total_entries && total_entries})
        </span>
      </h2>
      <button
        className={`filter_btn bg-color4 px-5 py-2 rounded-md cursor-pointer flex justify-center place-items-center gap-2`}
        onClick={() => dispatch(updateFiltrationView(true))}
      >
        <ReactSVG src={filterSVG} />
        <p className="filter_text !text-[20px] !font-[400]">Filter</p>
      </button>
      <div className="px-0 py-4 mx-auto">
        {/* ===========  */}
        <div className="ticketsGroup">
          <div className="">
            <div
              className={`${active
                ? "categorySidebarMobile categorysidebar !overflow-y-auto"
                : "categorysidebar"
                } w-full   !bg-color1  min-w-[370px] min-h-[388px] rounded-t-[8px] overflow-hidden px-2`}
            >
              <SidebarCategory
                tickets={true}
                setIsFilter={setIsFilter}
                startdate={startdate}
                setstartdate={setstartdate}
                enddate={enddate}
                setenddate={setenddate}
                setpage={setPage}
              />

            </div>
            <VerticalExpediaBanner />
          </div>


          <div className="flex flex-wrap -m-4 mt-3 gap-[20px] justify-center mb-5  ">
            {alleventsState && alleventsState?.length > 0
              ? alleventsState?.map((item, index) => {
                return (
                  <FeaturedEvent item={item} key={index} index={index + 1} />
                );
              })
              : value !== "Any Dates" ||
              (alleventsState?.length === 0 && AllEvents?.length === 0 && !isLoading && (
                <div className="flex justify-center place-items-start py-3 px-3">
                  <ReactSVG src={NotFoundSVG} />
                </div>
              ))}
            {

              isLoading && (
                <div className="flex flex-wrap -m-4 mt-3 gap-[20px] justify-center mb-5">
                  {
                    [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12].map((i) => {
                      return (
                        <LatestLoading key={i} />
                      )
                    })
                  }
                </div>
              )
            }
          </div>
        </div>
        {!isFilter
          ? CopyofAllEvents &&
          value === "Any Dates" &&
          AllEvents &&
          AllEvents?.length - 1 && <div ref={ref}></div>
          : Filterserachevents &&
          Filterserachevents?.length - 1 &&
          Filterserachevents?.length < total_entries && <div ref={ref}></div>}
      </div>
    </section>
  );
};

export default Tickets;