import React, { useEffect } from "react";
import { Toaster } from "react-hot-toast";
import RouterComponent from "./routes/Route";
import { BrowserRouter } from "react-router-dom";
import { TokenVerify } from "./redux/actions/User";
import LayoutIndex from "./Components/layout/Index";
import { useDispatch, useSelector } from "react-redux";
import { ScrollToTop } from "react-router-scroll-to-top";
// import ReactGA from "react-ga";

const App = () => {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user.user);
  const token = localStorage.getItem("instapass_user_token");

  useEffect(() => {
    if (token && !user?.email) {
      dispatch(TokenVerify());
    }
    // eslint-disable-next-line
  }, []);


  // === ga
  // useEffect(() => {
  //   ReactGA.initialize("G-JBXCSBCJSB");
  //   ReactGA.pageview(window.location.pathname + window.location.search);
  //   ReactGA.event({
  //     category: "User",
  //     action: "Logged in",
  //   });
  // }, []);


  return (
    <>
      <BrowserRouter>
        <LayoutIndex>
          <ScrollToTop />
          <Toaster position="top-right" />
          <RouterComponent user={user} />
        </LayoutIndex>
      </BrowserRouter>
    </>
  );
};

export default App;
