import React, { useEffect, useState } from "react";
import { ReactSVG } from "react-svg";

const PaymentMethods = ({ payWith , svg , title  , setPayWith , isPaymentInfoDataValue}) => {
  const [browser, setBrowser] = useState("");

  return (
    <div
    className={`paymentmethodboxchild border-[1px] border-shadowColor ${
       !isPaymentInfoDataValue ? "!cursor-no-drop" : null
    }  
       `}
      onClick={() => {
        if(!isPaymentInfoDataValue){
          return;
        }
        // if(browser !== "Safari" && payWith === 3){
        //     return;
        // }
        setPayWith(payWith);
      }}
    >
      <ReactSVG src={svg} />
      <p>{title}</p>
    </div>
  );
};

export default PaymentMethods;
// ${browser == "Safari" && payWith === 3 && "apply_bg"}