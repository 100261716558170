import React from "react";

const OrderSummery = ({ data, singleCouponCode, PaymentDetails, event }) => {
  const totalAmount =
    data?.retail_price * data?.qty +
    PaymentDetails?.retail?.tax +
    data?.service_fee;

  const percentageAmount =
    totalAmount - (totalAmount * singleCouponCode?.value) / 100;

  return (
    <div className="mt-6 w-full space-y-6 sm:mt-8 lg:mt-0 lg:max-w-xs xl:max-w-md !sticky !top-[50px]">
      <h2 className="text-xl font-semibold text-gray-500 dark:text-white">
        Order Summery
      </h2>
      <div className="flow-root">
        <div className="-my-3 divide-y divide-gray-200 dark:divide-gray-800">
          <div className="flex items-center justify-between gap-4 py-3">
            <dt className="text-base font-normal text-gray-500 dark:text-gray-400">
              Price/seat
            </dt>
            <dd className="text-base font-medium text-[white] dark:text-white">
              ${data?.retail_price}
            </dd>
          </div>
          <dl className="flex items-center justify-between gap-4 py-3">
            <dt className="text-base font-normal text-gray-500 dark:text-gray-400">
              Quantity
            </dt>
            <dd className="text-base font-medium text-[white] dark:text-white">
              {data?.qty}
            </dd>
          </dl>

          <dl className="flex items-center justify-between gap-4 py-3">
            <dt className="text-base font-normal text-gray-500 dark:text-gray-400">
              Service fee
            </dt>
            <dd className="text-base font-medium text-[white] dark:text-white">
              ${data?.service_fee}
            </dd>
          </dl>

          <dl className="flex items-center justify-between gap-4 py-3">
            <dt className="text-base font-normal text-gray-500 dark:text-gray-400">
              Discount
            </dt>
            <dd className="text-base font-medium text-green-500">
              {!singleCouponCode?.value
                ? 0
                : singleCouponCode?.percentage
                ? `${singleCouponCode?.value}%`
                : `${singleCouponCode?.value}$`}
            </dd>
          </dl>

          <dl className="flex items-center justify-between gap-4 py-3">
            <dt className="text-base font-normal text-gray-500 dark:text-gray-400">
              Tax
            </dt>
            <dd className="text-base font-medium text-[white] dark:text-white">
              ${PaymentDetails?.retail?.tax}
            </dd>
          </dl>
          <dl className="flex items-center justify-between gap-4 py-3">
            <dt className="text-base font-normal text-gray-500 dark:text-gray-400">
              Subtotal
            </dt>
            <dd className="text-base font-medium text-[white] dark:text-white">
              ${data?.retail_price} X {data?.qty} = $
              {(data?.retail_price * data?.qty).toFixed(2)}
            </dd>
          </dl>
          <dl className="flex items-center justify-between gap-4 py-3">
            <dt className="text-base font-bold text-gray-500 dark:text-white">
              Total
            </dt>
            <dd className="text-base font-bold text-[white] dark:text-white">
              ${" "}
              {singleCouponCode?.value
                ? singleCouponCode?.percentage
                  ? percentageAmount.toFixed(2)
                  : Math.max(totalAmount - singleCouponCode?.value, 0).toFixed(
                      2
                    )
                : totalAmount.toFixed(2)}
            </dd>
          </dl>
        </div>
      </div>
    </div>
  );
};

export default OrderSummery;
