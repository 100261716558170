import "./Index.css";
import Seatics from "./Seatics";
import Sidebar from "./Sidebar/Sidebar";
import Loadingcom from "../../Loading/Loading";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useParams } from "react-router-dom";
import { GetSingleEventById } from "../../../redux/actions/Events";

const Index = ({ id, ticketData }) => {
  const location = useLocation();
  const params = useParams()
  const dispatch = useDispatch();
  const [qty, setQty] = useState("");
  const [splitList, setSplitList] = useState([]);
  const AllEvents = useSelector((state) => state.events.AllEvents);
  const AllEventLoading = useSelector((state) => state.events.AllEventLoading);
  const [SeaticsData, setSeaticsData] = useState([]);
  const [filtertheEvent, setFiltertheEvent] = useState([]);
  // const receivedData = location.state?.data || (params?.item && JSON.parse(decodeURIComponent(params?.item)));
  const receivedData = location.state?.data;
  const reviceImage = location?.state?.image;


  // get parent event details by id
  useEffect(() => {
    if (id) {
      if (AllEvents?.length === 0 && filtertheEvent?.length === 0) {
        const fetchData = async () => {
          const result = await dispatch(GetSingleEventById(id));
          if (result) {
            setFiltertheEvent([result]);
          }
        };
        fetchData();
      }
    }
  }, [id, dispatch, AllEvents]);

  useEffect(() => {
    if (ticketData?.length !== 0) {
      const allSplits = ticketData?.map((data) => data?.splits);
      const flatArray = allSplits?.flat();
      setSplitList([...new Set(flatArray)]);
    }
  }, [ticketData]);




  return (
    <div className={`h-[90vh]  index_groupdeatails  `}>
      <div className="index_deatils  !bg-color1 !text-color2 h-[90vh]  overflow-y-hidden">
        {/* --- sidebar  */}
        <div className="!w-[400px] !h-[100%] !overflow-y-auto chactout_sidebar">
          <Sidebar
            filterevent={filtertheEvent}
            receivedData={receivedData}
            reviceImage={reviceImage}
            AllEventLoading={AllEventLoading}
            SeaticsData={SeaticsData}
            setSeaticsData={setSeaticsData}
            qty={qty}
            setQty={setQty}
            splitList={splitList}
            ticketData={ticketData}
          />
        </div>
        {/* --- content  */}
        <div className="w-full flex justify-center !text-color2 place-items-center h-[100%] py-5 px-5 ">
          <div className="parent_seat_map_wraper bg-color1  hide_image_div_in_mobile ">
            {AllEventLoading ? (
              <div>
                <Loadingcom />
              </div>
            ) : (
              window.innerWidth > 550 && (
                <Seatics
                  setSidebarData={setSeaticsData}
                  SeaticsData={SeaticsData}
                  GroupsEvents={ticketData}
                  event={
                    filtertheEvent?.length === 1
                      ? filtertheEvent && filtertheEvent[0]
                      : receivedData && receivedData[0]
                  }
                />
              )
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default React.memo(Index);
