import React from 'react'
import CreditCardPayment from '../../Payments/CreditCardPayment'
import PayPalPayment from '../../Payments/PayPalPayment'
import GooglePay from '../../Payments/GooglePay'
import ApplePay from '../../Payments/ApplePay'
import { RxCross1 } from 'react-icons/rx'

const PaymentMethods = ({payWith , setPayWith , onPaymentCompleted , data , PaymentDetails , createTevoClient , setError}) => {

  return (
    <>
      {[1].includes(payWith) && (
                <div className="fixed z-[999] payment_modal left-0 top-0 flex h-full w-full items-center justify-center bg-black bg-opacity-50 py-10">
                    <div className="max-h-full w-full max-w-xl overflow-y-auto sm:rounded-2xl bg-white">
                        <div className="relative w-full px-3">
                            <h2 className="my-3  text-[20px] font-bold">Payment</h2>
                            <div className="m-2 my-[30px] max-w-[800px] px-[20px] mx-auto  border-[1px]">
                                <div>
                                    <RxCross1
                                        className="absolute top-[5px] right-6 text-[23px] cursor-pointer text-color1"
                                        onClick={() => setPayWith(0)}
                                    />
                                    <CreditCardPayment
                                        show={payWith === 1 ? true : false}
                                        totalamount={data && (data?.retail_price * data?.qty) + data?.service_fee + PaymentDetails?.retail?.tax}
                                        onPaymentCompleted={onPaymentCompleted}
                                        setShow={setPayWith}
                                        createTevoClient={createTevoClient}
                                        setError={setError}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )}
            {[2].includes(payWith) && (
                <div className="fixed z-[29] payment_modal left-0 top-0 flex h-full w-full items-center justify-center bg-black bg-opacity-50 py-10">
                    <div className="max-h-full w-full max-w-xl overflow-y-auto sm:rounded-2xl bg-white">
                        <div className="relative w-full px-3">
                            <h2 className="my-3 text-[20px] font-bold">Payment</h2>

                            <div className="m-2 my-[30px] max-w-[450px] mx-auto">
                                <RxCross1
                                    className="absolute top-[5px] right-6 text-[23px] cursor-pointer text-color1"
                                    onClick={() => setPayWith(0)}
                                />

                                <PayPalPayment
                                    show={payWith === 2 ? true : false}
                                    totalamount={data && (data?.retail_price * data?.qty) + data?.service_fee + PaymentDetails?.retail?.tax}
                                    onPaymentCompleted={onPaymentCompleted}
                                    setShow={setPayWith}
                                    createTevoClient={createTevoClient}
                                    setError={setError}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            )}
            {[4].includes(payWith) && (
                <div className="fixed z-[29] payment_modal left-0 top-0 flex h-full w-full items-center justify-center bg-black bg-opacity-50 py-10 ">
                    <div className="max-h-full w-full max-w-xl overflow-y-auto sm:rounded-2xl bg-white ">
                        <div className="relative w-full px-3">
                            <h2 className="my-3  text-[20px] font-bold">Payment</h2>

                            <div className="m-2 my-[30px] max-w-[450px] mx-auto">
                                <RxCross1
                                    className="absolute top-[5px] right-6 text-[23px] cursor-pointer text-color1"
                                    onClick={() => setPayWith(0)}
                                />

                                <GooglePay
                                    show={payWith === 4 ? true : false}
                                    totalamount={data && (data?.retail_price * data?.qty) + data?.service_fee + PaymentDetails?.retail?.tax}
                                    onPaymentCompleted={onPaymentCompleted}
                                    setShow={setPayWith}
                                    createTevoClient={createTevoClient}
                                    setError={setError}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            )}
            {[3].includes(payWith) && (
                <div className="fixed z-[29] payment_modal left-0 top-0 flex h-full w-full items-center justify-center bg-black bg-opacity-50 py-10">
                    <div className="max-h-full w-full max-w-xl overflow-y-auto sm:rounded-2xl bg-white">
                        <div className="relative w-full px-3">
                            <h2 className="my-3 px-3 text-[20px] font-bold">Payment</h2>

                            <div className="m-2 my-[30px] max-w-[450px] mx-auto">
                                <RxCross1
                                    className="absolute top-[5px] right-6 text-[23px] cursor-pointer text-color1"
                                    onClick={() => setPayWith(0)}
                                />

                                <ApplePay
                                    show={payWith === 3 ? true : false}
                                    totalamount={data && (data?.retail_price * data?.qty) + data?.service_fee + PaymentDetails?.retail?.tax}
                                    onPaymentCompleted={onPaymentCompleted}
                                    setShow={setPayWith}
                                    createTevoClient={createTevoClient}
                                    setError={setError}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            )}
    </>
  )
}

export default PaymentMethods
