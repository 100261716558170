import React from "react";
import $ from "jquery";


const TicketBox = ({item , clickOnButton,index}) => {
  return (
    <div
      key={index}
      className="ticket-item py-2 px-2 border-b-[1px] border-b-shadowColor my-2 flex justify-between gap-3 place-items-center cursor-pointer hover:border-[1px] hover:border-redColor  "
      // onClick={() => { clickOnButton(item?.tgID) }}
      onClick={() => {
        clickOnButton(item?.tgID);
      }}
      style={{
        borderLeft: `8px solid  ${item?.section?.level?.color}`,
      }}
      onMouseEnter={() => {
        $(".ticket-item").hover(function () {
          const tgId = item?.tgID;
          const ticketGroup = window.Seatics.MapComponent.getTgFromId(tgId);
          if (ticketGroup) {
            window.Seatics.MapComponent.highlightTicket(ticketGroup);
          }
        });
      }}
    >
      <div className="w-full">
        <h2 className="mb-1 font-bold sidebar_ticket_name">
          Section {item?.tgUserSec || item?.section}
        </h2>
        <h2 className="mb-1 !font-[500] sidebar_ticket_name">
          Row : {item?.row || item?.tgUserRow} |{" "}
          {item?.available_quantity || item?.tgQty} tickets
        </h2>
        <div className="flex justify-between place-items-center gap-1">
          <p className="text-color5 ticket_available_count !text-[14px]">
            {item?.tgCType}
          </p>
        </div>
      </div>
      <div className="">
        <div className="bg_linear flex flex-col text-white px-2 py-2 rounded-md min-w-[100px] text-[18px] text-[600] text-center">
          ${item?.tgPrice}/ea
          {/* <p className="text-[11px] font-[400]">ALL-IN NO FEES</p> */}
        </div>
      </div>
    </div>
  );
};

export default TicketBox;
