import { ApiURL } from "../../setting/GlobleVariable";
import {
  APPLY_COUPON_ERROR,
  APPLY_COUPON_REQUEST,
  APPLY_COUPON_REQUEST_FAIL,
  APPLY_COUPON_SUCCESS,
  COUPON_CODE_CREATE_ERROR,
  COUPON_CODE_CREATE_REQUEST,
  COUPON_CODE_CREATE_REQUEST_FAIL,
  COUPON_CODE_CREATE_SUCCESS,
} from "../constant/CouponCode";
import toast from "react-hot-toast";

// ---- create
export const createCoupon = (fromdata) => async (dispatch) => {
  try {
    dispatch({ type: COUPON_CODE_CREATE_REQUEST });
    const res = await fetch(`${ApiURL}/couponcode/create`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        token: localStorage.getItem("instapass_user_token"),
      },
      body: JSON.stringify(fromdata),
    });
    dispatch({ type: COUPON_CODE_CREATE_REQUEST_FAIL });
    const data = await res.json();
    if (!data || res.status === 400) {
      return toast.error(data.message, { duration: 5000 });
    }
    if (res.status === 200) {
      dispatch({ type: COUPON_CODE_CREATE_SUCCESS, payload: data.CouponCodes });
      toast.success(data.message, { duration: 3000 });
    }
  } catch (error) {
    dispatch({ type: COUPON_CODE_CREATE_ERROR });
  }
};

//  ---- get all coupon code
export const getAllCoupons = () => async (dispatch) => {
  try {
    dispatch({ type: COUPON_CODE_CREATE_REQUEST });
    const res = await fetch(`${ApiURL}/couponcode/all`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        token: localStorage.getItem("instapass_user_token"),
      },
    });
    dispatch({ type: COUPON_CODE_CREATE_REQUEST_FAIL });
    const data = await res.json();
    if (!data || res.status === 400) {
      return toast.error(data.message, { duration: 5000 });
    }
    if (res.status === 200) {
      dispatch({ type: COUPON_CODE_CREATE_SUCCESS, payload: data.CouponCodes });
    }
  } catch (error) {
    dispatch({ type: COUPON_CODE_CREATE_ERROR });
  }
};

// ------ update coupon

export const UpdateCoupon = (id, discount, promoCode) => async (dispatch) => {
  try {
    dispatch({ type: COUPON_CODE_CREATE_REQUEST });
    const res = await fetch(`${ApiURL}/couponcode/update/${id}`, {
      method: "put",
      headers: {
        "Content-Type": "application/json",
        token: localStorage.getItem("instapass_user_token"),
      },
      body: JSON.stringify({ discount, promoCode }),
    });
    dispatch({ type: COUPON_CODE_CREATE_REQUEST_FAIL });
    const data = await res.json();
    if (!data || res.status === 400) {
      return toast.error(data.message, { duration: 5000 });
    }
    if (res.status === 200) {
      toast.success(data.message, { duration: 5000 });
      dispatch({ type: COUPON_CODE_CREATE_SUCCESS, payload: data.CouponCodes });
    }
  } catch (error) {
    dispatch({ type: COUPON_CODE_CREATE_ERROR });
  }
};

// --- delete
export const DeleteCoupon = (id) => async (dispatch) => {
  try {
    dispatch({ type: COUPON_CODE_CREATE_REQUEST });
    const res = await fetch(`${ApiURL}/couponcode/delete/${id}`, {
      method: "delete",
      headers: {
        "Content-Type": "application/json",
        token: localStorage.getItem("instapass_user_token"),
      },
    });
    dispatch({ type: COUPON_CODE_CREATE_REQUEST_FAIL });
    const data = await res.json();
    if (!data || res.status === 400) {
      return toast.error(data.message, { duration: 5000 });
    }
    if (res.status === 200) {
      toast.success(data.message, { duration: 5000 });
      dispatch({ type: COUPON_CODE_CREATE_SUCCESS, payload: data.CouponCodes });
    }
  } catch (error) {
    dispatch({ type: COUPON_CODE_CREATE_ERROR });
  }
};

// ---- apply coupon code

export const ApplyCoupon = (couponCode , spend) => async (dispatch) => {
  try {
    dispatch({ type: APPLY_COUPON_REQUEST });
    const res = await fetch(`${ApiURL}/couponcode/apply`, {
      method: "post",
      headers: {
        "Content-Type": "application/json",
        token: localStorage.getItem("instapass_user_token"),
      },
      body: JSON.stringify({ couponCode , spend }),
    });
    dispatch({ type: APPLY_COUPON_REQUEST_FAIL });
    const data = await res.json();
    if (!data || res.status === 400) {
      return toast.error(data.message, { duration: 5000 });
    }
    if (res.status === 200) {
      toast.success(data.message, { duration: 5000 });
      dispatch({ type: APPLY_COUPON_SUCCESS, payload: data.coupon });
    }
  } catch (error) {
    dispatch({ type: APPLY_COUPON_ERROR });
  }
};

// -- remove coupon code
export const RemoveCoupon = () => async (dispatch) => {
  dispatch({ type: "REMOVE_COUPON_CODE" });
};
