import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { GetEventsDeatils } from "../redux/actions/Events";
import Index from "../Components/PagesCompoents/GroupDeatils/Index";

const GroupDetails = () => {
  const params = useParams();
  const { id } = params;
  const [ticketsData, setTicketsData] = useState([]);
  const dispatch = useDispatch();

  
  useEffect(() => {
    const fetchTickest = async () => {
      const result = await dispatch(GetEventsDeatils(parseInt(id)));
      if (result?.success) {
        setTicketsData(result?.data);
      } else {
        console.log("Failed to fetch ticket groups");
      }
    }
    fetchTickest();
  }, [id]);



  return (
    <div className="mt-2">
      <Index id={id}
        ticketData={ticketsData}
      />
    </div>
  );
};

export default GroupDetails;

